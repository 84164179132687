import React, { Fragment } from 'react'

const MinutesText = () => {

  let text = "Minutes"

  return (
    <Fragment>
      <span>{text}</span>
    </Fragment>    
  )
}

export default MinutesText
